import { useState, useEffect } from 'react'
import api from '../api'
import { lang, addLeadingZeros } from '../lang'
import classes from '../../assets/css/form.module.css';

export default function Create(props) {
	const today = new Date().toISOString().split('T')[0];
  const email = localStorage.getItem("email");
  const {styles} = props;
  const [holidays, setHolidays] = useState([]);
  const [listsdata, setListsdata] = useState([]);
  const [all_events, setAllEvents] = useState([]);
  const [room_id, setRoomID] = useState("1");
  const [exception, setException] = useState('');
  const [booking_date, setBookingDate] = useState(today);
  const [start_time, setStartTime] = useState('');
  const [end_time, setEndTime] = useState('');
  const [booking_by, setBookingBy] = useState('');
  const [booking_email, setBookingEmail] = useState(email);
  const [booking_time, setBookingTime] = useState('');
  const [title, setTitle] = useState('');
  const [reason, setReason] = useState('อ่านหนังสือ');
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState(0);
  const [success, setSuccess] = useState(0);
  const [msg, setMsg] = useState('');
  const [config, setConfig] = useState({});
  const [start_times, setStartTimes] = useState([]);
  const [end_times, setEndTimes] = useState([]);
  const [reasons, setReasons] = useState([]);
  const max_hr = 4;

    

  const fetchInit = async () =>{
      try {
          const room_response = await api.get('/room');
          setRooms(room_response.data.results);
          let tmp = '';
          room_response.data.results.forEach(element => {
                if(element.id===room_id){
                    tmp = lang(element.detail,element.detail_en);
                }
          });
          setException(tmp);
          const response = await api.get('/rsvn_setting');
          const result = response.data.results;
          setConfig(result);
          let time_lists = getTimeList(result.start,result.end,30);
          setReasons(result.reason_lists.split('|'));
          setStartTimes(time_lists);
          setStartTime(time_lists[0]);
          setEndTime(time_lists[1]);
          let [start_hr,start_min] = result.start.split(":");
          let end_hr = Number(start_hr)+max_hr;
          let config_end = new Date("2023-02-27 "+result.end)
          let end = end_hr+':'+start_min
          if(config_end<new Date("2023-02-27 "+end_hr+':'+start_min)){
              end  = result.end;
          }
          const end_time_lists = getTimeList(time_lists[1],end,30);
          setEndTimes(end_time_lists);

          const rsvn_response = await api.get('/rsvn');
          const results = rsvn_response.data.results;
          setListsdata(results);
          let allevents = [];
          results.forEach(element => {
              let item = {
                  title: lang(element.room_name,element.room_name_en),
                  start  : element.booking_date+'T'+element.start_time+':00',
                  end  : element.booking_date+'T'+element.end_time+':00',
                  item  : element,
                  type  : "booking",
                  allDay : false

              }
              allevents.push(item);
          });
          
          const holiday_response = await api.get('/holiday');
          const holiday_results = holiday_response.data.results;
          holiday_results.forEach(element => {
              var end_date = new Date(element.end);
              let item = {
                    title: lang(element.detail,element.detail_en),
                //   start  : new Date(element.start),
                //   end  : end_date.setDate(end_date.getDate() + 1),
                    start  : element.start+'T00:00:01',
                    end  : element.end+'T23:59:59',
                    item  : element,
                    type  : "holiday",
                    allDay : true

              }
              allevents.push(item);
          });
          setHolidays(holiday_results);
          setAllEvents(allevents);

      } catch (err) {
          if(err.response){
              //Not in 202 response range
              console.log(err.response.data);
              console.log(err.response.headers);
              console.log(err.response.status);
          } else {
              console.log(`Error: ${err.message}`);
          }
      }
  }

  useEffect(()=>{
      let allevents = [];
      listsdata.forEach(element => {
          let item = {
              title: lang(element.room_name,element.room_name_en),
              start  : element.booking_date+'T'+element.start_time+':00',
              end  : element.booking_date+'T'+element.end_time+':00',
              item  : element,
              type  : "booking",
              allDay : false

          }
          allevents.push(item);
      }); 
      
      holidays.forEach(element => {
          var end_date = new Date(element.end);
          let item = {
              title: lang(element.detail,element.detail_en),
              start  : element.start+'T00:00:01',
            //   end  : end_date.setDate(end_date.getDate() + 1),
              end  : element.end+'T23:59:59',
              item  : element,
              type  : "holiday",
              allDay : true

          }
          allevents.push(item);
      });
      setAllEvents(allevents);
  },[listsdata]);

  useEffect(()=>{
      if(!email){
          window.location = process.env.PUBLIC_URL + '/rsvn?menu=login';
      }

      let cmu_account = JSON.parse(localStorage.getItem("cmu_account"));
      if(cmu_account){
          if(cmu_account.name){
            setBookingBy(cmu_account.name);
          }else{
            setBookingBy(cmu_account.prename_TH+' '+cmu_account.firstname_TH+' '+cmu_account.lastname_TH);
          }
      }
      fetchInit();
  },[]);

  const getTimeList = (start_time, end_times, mins) => {
      
      let start = new Date("2023-02-27 "+start_time)
      let end = new Date("2023-02-27 "+end_times)

      let slots = [];
      while (start <= end) {
          // console.log("start:"+start);
          // only mins
          //slots.push(date(start).getMinutes());
          
          let d = new Date(start);
          // hrs +  mins
          slots.push(`${addLeadingZeros(d.getHours(),2)}:${addLeadingZeros(d.getMinutes(),2)}`);
          start.setMinutes(start.getMinutes() + mins);
      }
      // console.log(slots);
      return slots;
  }

  const updateTimeList = (state,time) => {
      if(state === 'start'){
          setStartTime(time);

          setEndTime(current=>{
                let end_time = config.end;
                var dayOfWeek = new Date(booking_date).getDay();
                // reset time list in wednesday + thursday - 30-06-2023 to 31-07-2023
                if(Date.parse(booking_date)<Date.parse('2023-07-31')){
                    if(dayOfWeek===4||dayOfWeek===3){
                        end_time = '18:30';
                    }
                }

              let [start_hr,start_min] = time.split(":");
              let end_hr = Number(start_hr)+max_hr;
              let config_end = new Date("2023-02-27 "+end_time)
              let end = end_hr+':'+start_min
              if(config_end<new Date("2023-02-27 "+end_hr+':'+start_min)){
                  end  = end_time;
              }
              // console.log(start_times[start_times.indexOf(time)+1]);
              // console.log(end);

              const end_time_lists = getTimeList(start_times[start_times.indexOf(time)+1],end,30);
              setEndTimes(end_time_lists);

              return start_times[start_times.indexOf(time)+1];
          })


      } else if(state === 'end'){
          setEndTime(time);
      }
  }

  const isHoliday = (event_date) => {
      const filterEvent = all_events.filter((element: any) => {
          // console.log(new Date(element.start).toISOString().split('T')[0]);
          if((new Date(event_date).getTime() <= new Date(element.end).getTime() && new Date(event_date).getTime() >= new Date(element.start).getTime())) {
            console.log(event_date);
            console.log(element.end);
            console.log(element.start);
              return element.type === 'holiday' 
          }
      });

      if(filterEvent.length>0){
          return true;
      }else{
          return false;
      }

  }

  function isBetween(h,m, h1, m1, h2, m2,state,room) {
      // console.log('check: '+room+' '+state+' : '+h+':'+m+','+h1+':'+m1+','+h2+':'+m2);
      if(state==="start"){
          return (h1 < h || (h1 === h && m1 <= m)) && (h < h2 || (h === h2 && m < m2));
      }else{
          return (h1 < h || (h1 === h && m1 < m)) && (h < h2 || (h === h2 && m <= m2));
      }
  }

  const isWeekEnd = (event_date) => {
      var dayOfWeek = new Date(event_date).getDay();
      if((dayOfWeek === 6) || (dayOfWeek  === 0)){
          return true;
      }else{
          return false;
      }

  }
  
  const isCoverage = (start_hr,start_min, end_hr,end_min, h1, m1, h2, m2,room) => {
    if((start_hr>h1||(start_hr===h1&&start_min>=m1))&&(end_hr<h2||(end_hr===h2&&end_min<=m2))){
        // console.log("coverage time");
        return true;
    }
    return false;
  }

  const isEmpty = () => {
      const filterEvent = all_events.filter((element: any) => {
          if(element.type==="booking"&&element.start.split('T')[0]===booking_date&& element.item.room_id===room_id){
          let [start_hr,start_min] = element.start.split('T')[1].split(':');
          let [end_hr,end_min] = element.end.split('T')[1].split(':');
          let [hr,min] = start_time.split(':');
          let [hr2,min2] = end_time.split(':');

          return (isBetween(hr,min,start_hr,start_min,end_hr,end_min,"start",element.item.room_name)||
                      isBetween(hr2,min2,start_hr,start_min,end_hr,end_min,"end",element.item.room_name)||isCoverage(start_hr,start_min,end_hr,end_min,hr,min,hr2,min2,element.item.room_name));
          }
      });

    //   console.log(filterEvent.length);
      if(filterEvent.length>0){
          return false;
      }else{
          return true;
      }

  }

  
    function handleSubmit(event){
        event.preventDefault();
        setError(0);
        setSuccess(0);
        setMsg('');

        if(!booking_date){
            let message = lang('กรุณากรอกวันที่จอง..','Please enter Booking Date..');
            setError(1);
            setMsg(message);
            return
        }else if(!start_time){
            let message = lang('กรุณากรอกเวลาเริ่มต้น..','Please enter Start Time..');
            setError(1);
            setMsg(message);
            return
        }else if(!isEmpty()){
            // console.log("between another transaction or collapse");
            let message = lang('ไม่สามารถจองห้องในเวลานี้ได้..','Can\'t make a reservation at this time..');
            setError(1);
            setMsg(message);
            return
        }else if(!room_id){
            let message = lang('กรุณาเลือกห้อง..','Please enter Room ID..');
            setError(1);
            setMsg(message);
            return
        }else if(!booking_by){
            let message = lang('กรุณากรอกชื่อผู้จอง..','Please enter Booking By..');
            setError(1);
            setMsg(message);
            return
        }else if(!booking_email){
            let message = lang('กรุณากรอกอีเมล์ผู้จอง..','Please enter Email..');
            setError(1);
            setMsg(message);
            return
        }else if(isHoliday(booking_date)){
            let message = lang('ไม่สามารถจองในวันนี้ได้เนื่องจากเป็นวันหยุด..','Can\'t make a reservation at today..');
            setError(1);
            setMsg(message);
            return
        }else if(isWeekEnd(booking_date)){
            let message = lang('ไม่สามารถจองในวันนี้ได้เนื่องจากเป็นวันหยุด..','Can\'t make a reservation at today..');
            setError(1);
            setMsg(message);
            return
        }else{
            // create new booking
            const user=localStorage.getItem('email');
            const ip=localStorage.getItem('ip');
            console.log("create new booking");
            const jsonData = {
                room_id: room_id,
                booking_date: booking_date,
                start_time: start_time,
                end_time: end_time,
                booking_by: booking_by,
                booking_email: booking_email,
                booking_time: booking_time,
                title: title,
                reason: reason,
                status: 1,
                user: user,
                ip: ip,
            } 
            api.post(`rsvn/create`,jsonData)
            .then(response => {
                let list_data = [];
                if(response.data.status==='ok'){
                    list_data = response.data.results;
                    setListsdata(list_data);
                    setSuccess(1);
                }else{
                    setError(1);
                    let message = JSON.stringify(response.data.message);
                    setMsg(message);
                }
            })
            .catch(error => {
                setError(1);
                setMsg(error);
            });
        }
    }

    const onChangeRoom = (roomid) => {
        setRoomID(roomid);
        let tmp = '';
        rooms.forEach(element => {
              if(element.id===roomid){
                  tmp = lang(element.detail,element.detail_en);
              }
        });
        setException(tmp);
    }

    const onChangeDate = (date_value) => {
        setBookingDate(date_value);

        var dayOfWeek = new Date(date_value).getDay();
        // reset time list in wednesday + thursday - 30-06-2023 to 31-07-2023
        if(Date.parse(date_value)<Date.parse('2023-07-31')){
            let end_time = '16:30';
            if(dayOfWeek===4||dayOfWeek===3){
                end_time = '18:30';
            }
            let time_lists = getTimeList(config.start,end_time,30);
            setStartTimes(time_lists);
            setStartTime(time_lists[0]);
            setEndTime(time_lists[1]);
            let [start_hr,start_min] = config.start.split(":");
            let end_hr = Number(start_hr)+max_hr;
            let config_end = new Date("2023-02-27 "+end_time)
            let end = end_hr+':'+start_min
            if(config_end<new Date("2023-02-27 "+end_hr+':'+start_min)){
                end  = end_time;
            }
            const end_time_lists = getTimeList(time_lists[1],end,30);
            setEndTimes(end_time_lists);
        }

    }

  return (
    <div>
        <h3>
            {lang("จองห้อง","Booking")}
        </h3>
        <div className={classes["booking-form"]}>
            {(error===1)?(
                <div className="notice danger">
                    <div><i className="fa-solid fa-triangle-exclamation"></i></div>
                    <div className="info">
                        <b>{lang('ไม่สามารถบันทึกข้อมูลได้','Can\'t booking..')}</b><br />
                        {msg}
                    </div>
                </div>
                ):''}
            {(success===1)?(
            <div className="notice active">
                <div><i className="fa-regular fa-file-lines"></i></div>
                <div className="info">
                    <b>{lang('บันทึกข้อมูลเรียบร้อย!','Successfuly!')}</b>
                    <br />{lang('ทำรายการบันทึกข้อมูลเรียบร้อย..','Complete booking!')}
                </div>
                <div>
                    <i className="fa-solid fa-xmark btn-close" onClick={(event)=>setSuccess(0)}></i>
                </div>
            </div>
            ):''}
            <form className={classes["main-form"]}>
                <fieldset>
                    <label htmlFor="room_id">{lang('ห้อง','Room')}</label>
                    <div className="input-group">
                    <select name="room_id" id="room_id" value={(room_id)?room_id:0} onChange={e=>onChangeRoom(e.target.value)}>
                        { rooms.map((option,index) => (
                            <option value={option.id} key={'room-'+index}>{lang(option.name,option.name_en)}</option>
                        )) }
                    </select>
                    <span className={classes["etc"]}>{(exception!=='')?'* '+exception:''}</span>
                    </div>
                </fieldset>
                <fieldset>
                    <label htmlFor="booking_date">{lang('วันที่จอง','Booking Date')}</label>
                    <input type="date" min={today} name="booking_date" id="booking_date" value={(booking_date)?booking_date:''} onChange={e=>onChangeDate(e.target.value)} />
                </fieldset>
                <fieldset>
                    <label htmlFor="start_time">{lang('เวลาเริ่มต้น','Start Time')}</label>
                    <select name="start_time" id="start_time" value={(start_time)?start_time:0} onChange={e=>updateTimeList('start',e.target.value)}>
                        {start_times.map((option,index) => (
                            (index!==start_times.length-1)?
                                <option value={option} key={'start-'+index}>{option}</option>
                            :''
                        ))}
                    </select>
                </fieldset>
                <fieldset>
                    <label htmlFor="end_time">{lang('เวลาสิ้นสุด','End Time')}</label>
                    <select name="end_time" id="end_time" value={(end_time)?end_time:start_time} onChange={e=>updateTimeList('end',e.target.value)}>
                        {end_times.map((option,index) => (
                            <option value={option} key={'end-'+index}>{option}</option>
                        ))}
                    </select>
                </fieldset>
                <fieldset className={classes["hide"]}>
                    <label htmlFor="booking_by">{lang('จองโดย','Booking By')}</label>
                    <input type="text" name="booking_by" id="booking_by" value={(booking_by)?booking_by:''} onChange={e=>setBookingBy(e.target.value)} />
                </fieldset>
                <fieldset className={classes["hide"]}>
                    <label htmlFor="booking_email">{lang('อีเมล์ผู้จอง','Email')}</label>
                    <input type="text" name="booking_email" id="booking_email" value={(booking_email)?booking_email:''} onChange={e=>setBookingEmail(e.target.value)} />
                </fieldset>
                <fieldset>
                    <label htmlFor="reason">{lang('เหตุผล','Reason')}</label>
                    <select name="reason" id="reason" value={(reason)?reason:'อื่นๆ'} onChange={e=>setReason(e.target.value)}>
                        {reasons.map((option,index) => (
                            <option value={option} key={'room-'+index}>{option}</option>
                        ))}
                    </select>
                </fieldset>
                <fieldset>
                    <label htmlFor="title">{lang('รายละเอียด','Detail')}</label>
                    <textarea name="title" id="title" value={(title)?title:''} onChange={e=>setTitle(e.target.value)} />
                </fieldset>
                <fieldset>
                    <button onClick={e=>handleSubmit(e)} className={classes["btn"]}>{lang('จองห้อง','Make booking')}</button>
                </fieldset>
            </form>
        </div>
    </div>
  )
}
