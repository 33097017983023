import React, { useState, useEffect ,useRef} from 'react'
import api from '../api'
import { lang, thDateFormat,addLeadingZeros, enDateFormat } from '../lang'
import Alert from "sweetalert2";

export default function DayCalendar(props) {
    let { styles, rooms, all_events, select_date, showButton, setCalendarMode} = props;
    const today = new Date().toISOString().split('T')[0];
    const [event_date, setEventDate] = useState(new Date(today+' 12:00:00'));
    // new Date().toISOString().split('T')[0]
    const [config, setConfig] = useState({});
    const [time_line, setTimeLine] = useState([]);
    const [all_rooms, setAllRooms] = useState([]);
    const [timelist, setTimeList] = useState([]);
    const containerRef = useRef();
    // scroll to open time remove because set init time to open time
    // if(document.querySelector('#activity-container')){
        // var slider = document.querySelector('#activity-container');
        // slider.scrollLeft=475
    // }
    
    const fetchInit = async () =>{
        try {
            const response = await api.get('/rsvn_setting');
            const result = response.data.results;
            setConfig(result);
            let timeline = [];
            let timelst = [];
            for(let i=parseInt(result.start.split(':')[0]);i<=parseInt(result.end.split(':')[0]);i++){
                // timeline.push(i);
            }
            for(let i=8;i<=22;i++){
                let data='';
                if(i===0){
                    data+='12 ';
                }else if(i>12){
                    data+=(i-12)+' ';
                }else{
                    data+=i+' ';
                }

                data += i < 12 ? 'AM' : 'PM';
                timeline.push(lang(addLeadingZeros(i,2)+':00',data));
                timelst.push(addLeadingZeros(i,2)+':00');
            }
            setTimeLine(timeline);
            setTimeList(timelst);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(()=>{
        fetchInit();
    },[]);

    useEffect(()=>{
        if(Object.prototype.toString.call(select_date) === '[object Date]'){
            setEventDate(select_date);
        }
    },[select_date]);


    useEffect(()=>{
        let allrooms = []
        let type_chk = 0;
        let data = {};
        rooms.forEach(element => {
            if(type_chk!==element.room_type_id){
                type_chk = element.room_type_id
                data = {id:0,name:element.room_type,name_en:element.room_type_en,group:1}
                allrooms.push(data);
            }
            allrooms.push(element);
        });
        setAllRooms(allrooms);
    },[rooms]);

    const holiday = () => {
        const filterEvent = all_events.filter((element: any) => {
            // console.log(new Date(element.start).toISOString().split('T')[0]);
            if((new Date(event_date).getTime() <= new Date(element.end).getTime() && new Date(event_date).getTime() >= new Date(element.start).getTime())) {
                return element.type === 'holiday' 
            }
        });

        if(filterEvent.length>0){
            return (
                <span className={styles["holiday"]}>({lang(filterEvent[0].title,filterEvent[0].title_en)})</span>
            )
        }

        let weekday = new Date(event_date).getDay();
        if(weekday===0){
            return (
                <span className={styles["holiday"]}>({lang('วันอาทิตย์','Sunday')})</span>
            )
        }else if(weekday===6){
            return (
                <span className={styles["holiday"]}>({lang('วันเสาร์','Saturday')})</span>
            )
        }
    }

    const eventBlock = (room_name, time) => {
        const filterEvent = all_events.filter((element: any) => {
            return element.item.room_name === room_name && 
                    element.start.split('T')[0]===event_date.toISOString().split('T')[0] && 
                    element.start.split('T')[1]===time;
        });
        if(filterEvent.length>0){
            let [start_hr,start_min] = filterEvent[0].start.split('T')[1].split(':');
            let [end_hr,end_min] = filterEvent[0].end.split('T')[1].split(':');
            let count = 0;
            let start = Number(start_hr);
            if(start_min === '30'){
                start += 0.5
            }
            let end = Number(end_hr);
            if(end_min === '30'){
                end += 0.5
            }
            for(let i=start;i<end;i+=0.5){
                    count++;
            }
            let width = count*30-1;

            return (
                <div className={styles["rsvn-block"]} onClick={e=>eventClick(filterEvent[0])} style={{width:width+"px"}} title={filterEvent[0].title+' - '+filterEvent[0].start.split('T')[1].slice(0,-3) + "-" + filterEvent[0].end.split('T')[1].slice(0,-3)}>
                    <div className={styles["event-dot"]}></div>
                    <div className={styles["event-time"]}>{filterEvent[0].start.split('T')[1].slice(0,-3)}</div>
                    <div className={styles["event-title"]}>{filterEvent[0].item.reason}</div>
                </div>
            )
        }
    }

  /**
   * when we click on event we are displaying event details
   */
    const eventClick = (event) => {
        let item = event.item;
        let more_detail = '';
        if(item.title){
            more_detail = `
            <tr>
                <td>${lang('รายละเอียด','Info')}</td>
                <td><strong>${item.title}</strong></td>
            </tr>`;
        }
        Alert.fire({
            title: event.title,
            html:
                `<div class="table-responsive">
            <table class="table">
            <tbody>
                <tr>
                    <td>${lang('รายการ','Title')}</td>
                    <td><strong>` + event.title + `</strong></td>
                </tr>
                <tr>
                    <td>${lang('วันที่','Date')}</td>
                    <td><strong>` + lang(thDateFormat(item.booking_date),enDateFormat(item.booking_date)) + `</strong></td>
                </tr>
                <tr>
                    <td>${lang('เวลา','Time')}</td>
                    <td><strong>` + item.start_time + ' - ' + item.end_time + `</strong></td>
                </tr>
                <tr>
                    <td>${lang('จองโดย','Booking by')}</td>
                    <td><strong>` + item.booking_by + `</strong></td>
                </tr>
                <tr>
                    <td>${lang('เหตุผล','reason')}</td>
                    <td><strong>` + item.reason + `</strong></td>
                </tr>` + more_detail + `
            </tbody>
            </table>
            </div>`,

            showDenyButton: false,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            denyButtonColor: "#d33",
            confirmButtonText: lang("ปิดหน้าต่าง","Close"),
            cancelButtonText: lang("กลับหน้าหลัก","Back"),
            denyButtonText: lang("ยกเลิกการจอง","Cancel"),
        }).then(result => {
            // if (result.isConfirmed) {
            // //   eventClick.event.remove(); // It will remove event from the calendar
            //     openModal(item.id);
            // }else if (result.isDenied) {
            //     //   eventClick.event.remove(); // It will remove event from the calendar
            //         openRmModal(item.id);
            //     }
        });
    };

    
    const setMyDate = (type,mydate) => {
        let set_date = event_date;
        if(type==="add"){
            set_date = new Date(mydate.setDate(mydate.getDate()+1));
        }else if(type==="sub"){
            set_date = new Date(mydate.setDate(mydate.getDate()-1));
        }
        console.log(set_date);
        setEventDate(set_date)
    }

    return (
        <div className="fc fc-media-screen fc-direction-ltr fc-theme-standard">
            <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
                <div className="fc-toolbar-chunk">
                    <button type="button" title="วันนี้" onClick={e=>setEventDate(new Date(today+" 12:00:00"))} className="fc-today-button fc-button fc-button-primary" disabled={(event_date.toISOString().split('T')[0]===today)?true:false}>{lang("วันนี้","Today")}</button>
                </div>
                <div className="fc-toolbar-chunk">
                    <div className="fc-button-group">
                        <button type="button" title="ก่อนหน้า" onClick={e=>setMyDate('sub',event_date)} className="fc-prev-button fc-button fc-button-primary">
                                <span className="fc-icon fc-icon-chevron-left"></span></button>
                        <h2 className="fc-toolbar-title" id="fc-dom-86">{lang(thDateFormat(event_date.toISOString().split('T')[0]),enDateFormat(event_date.toISOString().split('T')[0]))} {holiday()}</h2>
                        <button type="button" title="ถัดไป" onClick={e=>setMyDate('add',event_date)} className="fc-next-button fc-button fc-button-primary">
                                <span className="fc-icon fc-icon-chevron-right"></span></button>
                    </div>
                </div>
                <div className="fc-toolbar-chunk">
                    {(showButton===true)?
                        <a title="เดือน" href={process.env.PUBLIC_URL + "/rsvn?menu=create"} className="fc-button fc-button-primary"><i className="fa-solid fa-calendar-days"></i> {lang('จองห้อง','Booking')}</a>
                    :''}
                    <button type="button" title="เดือน" onClick={e=>setCalendarMode("monthly")} className="fc-button fc-button-primary">{lang('รายเดือน','Monthly')}</button>
                </div>
            </div>
            <div className={styles["day-calendar-container"]}>
                <div className={styles["room"]}>
                    <ul>
                        <li>{lang("รายชื่อห้อง","Room Name")}</li>
                        { all_rooms.map((item,index) => (
                            <li key={"room-"+index} className={(item.group===1)?styles["room-group"]:''}>{lang(item.name,item.name_en)}</li>
                        )) }
                    </ul>
                </div>
                <div className={styles["activity-container"]} id={"activity-container"} ref={containerRef}>
                    <table>
                        <thead>
                        <tr>
                            { time_line.map((item,index) => (
                                <th key={"hr-"+index}>{item}</th>
                            )) }
                        </tr>
                        </thead>
                        <tbody>
                        { all_rooms.map((item,index) => (
                        <tr key={"tb-room-"+index} className={(item.group===1)?styles["room-group"]:''}>
                            { timelist.map((titem,index) => (
                                <td key={"tb-hr-"+index}>
                                    <div className={styles["half-hr"]}>{(item.group!==1)?eventBlock(item.name,titem+':00'):''}</div>
                                    <div className={styles["half-hr"]}>{(item.group!==1)?eventBlock(item.name,titem.split(':')[0]+':30:00'):''}</div>
                                </td>
                            )) }
                        </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}