import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom';
import { lang, img_url } from '../components/lang'
import { useLocation } from "react-router-dom";
import { thDateFormat, enDateFormat } from '../components/lang'
import Alert from "sweetalert2";
import api from '../components/api'
import styles from '../assets/css/calendar.module.css';
import Menu from "../components/Booking/Menu";
import Nav from "../components/Template/Nav";
import Calendar from "../components/Booking/Calendar";
import DayCalendar from "../components/Booking/DayCalendar";
import Detail from "../components/Booking/Detail";
import Create from "../components/Booking/Create";
import Profile from "../components/Booking/Profile";
import Table from "../components/Booking/Table";
import Login from "../components/Booking/Login";
import Callback from "../components/Booking/Callback";
import Activate from "../components/Booking/Activate";

export default function Booking(props) {
    const [nav_data, setNavData] = useState([]);
    const search = useLocation().search;
    const search_params = new URLSearchParams(search);
    let init_menu = (search_params.get('menu'))?search_params.get('menu'):'calendar'
    const [menu, setMenu] = useState(init_menu);
    const [rooms, setRooms] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [events, setEvents] = useState([]);
    const [calendar_mode, setCalendarMode] = useState("daily"); 
    const [select_date, setSelectDate] = useState(new Date().toISOString().split('T')[0]);
    const email = localStorage.getItem("email");

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/room');
                setRooms(response.data.results);
                const menu_response = await api.get('/menu/37');
                setNavData(menu_response.data);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);
    
    useEffect(()=>{
        const fetchData = async () =>{
            try {
                if(rooms===[]){
                const room_response = await api.get('/room');
                    setRooms(room_response.data.results);
                }
                const response = await api.get('/rsvn');
                const results = response.data.results;
                let all_events = [];
                results.forEach(element => {
                    let item = {
                        title: lang(element.room_name,element.room_name_en),
                        start  : element.booking_date+'T'+element.start_time+':00',
                        end  : element.booking_date+'T'+element.end_time+':00',
                        item  : element,
                        type  : "booking",
                        allDay : false

                    }
                    all_events.push(item);
                });
                setBookings(results);


                
                const holiday_response = await api.get('/holiday');
                const holiday_results = holiday_response.data.results;
                holiday_results.forEach(element => {
                    var end_date = new Date(element.end);
                    let item = {
                        title: lang(element.detail,element.detail_en),
                        start  : new Date(element.start),
                        end  : end_date.setDate(end_date.getDate() + 1),
                        item  : element,
                        type  : "holiday",
                        allDay : true

                    }
                    all_events.push(item);
                });
                setEvents(all_events);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchData();

        // kick user if login more than 1 hr
        if(email){
            let login_time = new Date(localStorage.getItem("login_time"));
            // var ONE_HOUR = 60 * 60 * 1000;
            var TEN_MIN = 10* 60 * 1000;
            let now = new Date();
            if((now - login_time) > TEN_MIN){
                localStorage.removeItem("email");
                localStorage.removeItem("cmu_account");
                localStorage.removeItem("token");
                localStorage.removeItem("login_time");
                window.location = process.env.PUBLIC_URL + "/rsvn?menu=login"
            }
        }
    },[menu]);

    const dateClick = (info) => {
        // console.log(new Date(info.dateStr+" 12:00:00"));
        setSelectDate(new Date(info.dateStr+" 12:00:00"));

        setCalendarMode("daily");
    }

  /**
   * when we click on event we are displaying event details
   */
    const eventClick = (eventClick) => {
        if(eventClick.event.extendedProps.type==="booking"){
            let item = eventClick.event.extendedProps.item;
            let more_detail = '';
            if(item.title){
                more_detail = `
                <tr>
                    <td>${lang('รายละเอียด','Info')}</td>
                    <td><strong>${item.title}</strong></td>
                </tr>`;
            }
            Alert.fire({
                title: eventClick.event.title,
                html:
                    `<div class="table-responsive">
                <table class="table">
                <tbody>
                    <tr>
                        <td>${lang('รายการ','Title')}</td>
                        <td><strong>` + eventClick.event.title + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('วันที่','Date')}</td>
                        <td><strong>` + lang(thDateFormat(item.booking_date),enDateFormat(item.booking_date)) + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('เวลา','Time')}</td>
                        <td><strong>` + item.start_time + ' - ' + item.end_time + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('จองโดย','Booking by')}</td>
                        <td><strong>` + item.booking_by + `</strong></td>
                    </tr>
                    <tr>
                        <td>${lang('เหตุผล','reason')}</td>
                        <td><strong>` + item.reason + `</strong></td>
                    </tr>` + more_detail + `
                </tbody>
                </table>
                </div>`,

                showDenyButton: false,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                denyButtonColor: "#d33",
                confirmButtonText: lang("ปิดหน้าต่าง","Close"),
                cancelButtonText: lang("กลับหน้าหลัก","Back"),
                denyButtonText: lang("ยกเลิกการจอง","Cancel"),
            }).then(result => {
                // if (result.isConfirmed) {
                // //   eventClick.event.remove(); // It will remove event from the calendar
                //     openModal(item.id);
                // }else if (result.isDenied) {
                //     //   eventClick.event.remove(); // It will remove event from the calendar
                //         openRmModal(item.id);
                //     }
            });
        }else{
            let item = eventClick.event.extendedProps.item;
            let holiday = lang(thDateFormat(item.start),enDateFormat(item.start)) + ' - ' + lang(thDateFormat(item.end),enDateFormat(item.end));
            if(item.start === item.end){
                holiday = lang(thDateFormat(item.start),enDateFormat(item.start));
            }
            Alert.fire({
                title: eventClick.event.title,
                html:
                    `<div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>${lang('รายการ','Title')}</td>
                            <td><strong>` + eventClick.event.title + `</strong></td>
                        </tr>
                        <tr>
                            <td>${lang('วันที่','Date')}</td>
                            <td><strong>` + holiday + `</strong></td>
                        </tr>
                    </tbody>
                </table>
                </div>`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: "#3085d6",
                cancelButtonText: lang("ปิดหน้าต่าง","Close")
            }).then(result => {
            });
        }
    };

    // a custom render function
    function renderEventContent(eventInfo) {
        let type = eventInfo.event.extendedProps.type;
        return (
          <>
            {(type==='booking')?<div className="fc-event-dot"></div>:''}
            <div className="fc-event-time">{eventInfo.timeText}</div>
            <div className="fc-event-title">{eventInfo.event.title}</div>
          </>
        )
    }

    return (
        <div className={"container "+styles["calendar-container"]}>
              <Nav styles={styles} nav_data={nav_data} />
              <div className={styles["content"]}>
                  <Menu menu={menu} setMenu={setMenu} right={true} className={styles["menu"]}/>
                  <div className={styles["content-container"]}>
                    <div className={styles["calendar"]+" calendar"}>
                        {(menu==="calendar")?
                            (calendar_mode==="daily")?(
                                <DayCalendar select_date={select_date} styles={styles} 
                                    rooms={rooms} all_events={events} setCalendarMode={setCalendarMode}
                                />
                            ):(
                                <Calendar setCalendarMode={setCalendarMode} events={events} eventClick={eventClick} renderEventContent={renderEventContent} dateClick={dateClick}/>
                            )
                        :''}
                        {(menu==="callback")?
                            <Callback styles={styles} />
                        :''}
                        {(menu==="activate")?
                            <Activate styles={styles} />
                        :''}
                        {(menu==="table")?
                            <Table styles={styles} bookings={bookings} events={events} />
                        :''}
                        {(email)?
                            <>
                            {(menu==="create")?
                            <Create styles={styles} bookings={bookings} events={events} />
                            :''}
                            {(menu==="mybooking")?
                            <Detail styles={styles} all_bookings={bookings} menu={menu} />
                            :''}
                            {(menu==="login")?
                            <Login styles={styles} all_bookings={bookings} events={events} menu={menu} />
                            :''}
                            {(menu==="setting")?
                            <Profile styles={styles} />
                            :''}
                            </>
                        :
                        <>
                        {(menu==="create"||menu==="mybooking"||menu==="login")?
                            <Login styles={styles} all_bookings={bookings} events={events} menu={menu} />
                        :''}
                        </>}
                    </div>
                  </div>
              </div>
        </div>
    )
}